// Community Links
import medium from '../assets/social-links/medium.png'
import twitter from '../assets/social-links/twitter-logo.svg'
import youtube from '../assets/social-links/youtube.svg'
import gitbook from '../assets/social-links/gitbook-icon.svg'
import mail from '../assets/social-links/mail-icon.svg'




export const LINKS = {
  community: [
    {
      icon: medium,
      link: 'https://medium.com/apy-finance',
      name: 'Medium'
    },
    {
      icon: twitter,
      link: 'https://twitter.com/apyfinance',
      name: 'Twitter'
    },
    {
      icon: youtube,
      link: 'https://www.youtube.com/channel/UCPTiSrIFrIac5jSTmHzqJ8Q',
      name: 'YouTube'
    },
    {
      icon: gitbook,
      link: 'https://docs.apy.finance/',
      name: 'Gitbook'
    },
    {
      icon: mail,
      link: 'mailto:support@apy.finance',
      name: 'Email'
    },
  ],
  siteMap: [
    {
      title: 'docs',
      route: 'https://docs.apy.finance',
      type: 'anchor'
    },
    {
      title: 'help',
      route: 'mailto:info@apy.finance',
      type: 'anchor'
    },
    {
      title: 'privacy',
      route: '/privacy-policy',
      type: 'route'
    },
    {
      title: 'terms',
      route: '/terms-of-service',
      type: 'route'
    },
    {
      title: 'add farm',
      type: 'anchor',
      route: 'https://gv5qt5b8im5.typeform.com/to/Y8czQa3E'
    },
  ],
  navBar: [
    {
      body: 'strategies',
      type: 'hash',
      path: '/#strategies'
    },
    // {
    //   body: 'docs',
    //   type: 'anchor',
    //   path: 'https://docs.apy.finance'
    // },
    {
      body: 'governance',
      type: 'route',
      path: '/governance'
    },
    {
      body: 'investors',
      type: 'route',
      path: '/investors'
    },
    {
      body: 'press',
      type: 'route',
      path: '/press'
    },
 
  ]
}
