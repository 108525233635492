import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { BodyText, H3Title, SubText } from "../../shared/Headers";

import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { container } from "../../constants/variants";

const LineSVGMotion = styled(motion.svg)`
  width: 48%;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%)
    ${(props) => (props.isReverse ? "scaleX(-1)" : "")};
`;
const pathVariants1 = {
  visible: {
    pathLength: 1,
    transition: { duration: .75, delay: 1, ease: "easeInOut" },
  },
  hidden: { pathLength: 0 },
};
const LinesAnimated = ({ isReverse }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView]);
  return (
    <LineSVGMotion
      ref={ref}
      animate={controls}
      initial="hidden"
      className="square"
      isReverse={isReverse}
      isAnimate={true}
      width="1444"
      height="525"
      viewBox="0 0 1444 525"
      fill="none"
      overflow="visible"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        stroke="#432E72"
        strokeWidth={7}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m 0 0 v 284 h 1442 v 244"
        fill="none"
        variants={pathVariants1}
        strokeLinejoin="miter"
      />
    </LineSVGMotion>
  );
};
const Container = styled(motion.div)`
  color: #fff;
  padding: 1.5em 0;
  width: 100%;
  position: relative;
  flex-direction: ${(props) => (props.isReverse ? "row-reverse" : "row")};
  h3 {
    padding: 0;
  }
  margin-bottom: 1.2em;
  @media (min-width: 768px) {
    display: flex;
    margin-bottom: 0;
  }
`;

const Title = styled(SubText)``;
const Item = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  ${(props) =>
    props.type === "img" &&
    `
    padding:0;
    align-items: center;
    justify-content: center;
  `};
  margin-bottom: 2em;

  @media (min-width: 768px) {
    width: 50%;
    padding: 0 3.5em;
    margin-bottom: 0;
  }
`;
const SectionItem = ({
  title,
  header,
  subText,
  src,
  imgSize,
  variants,
  isReverse,
  isLast,
  scrollPosition,
}) => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [inView]);
  const item = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delayChildren: .2,
        delay: .8,
        ease: "easeInOut",
        duration: .8,
        staggerChildren: 0.4,
      },
    },
  };
  return (
    <>
      <Container
        isReverse={isReverse}
        ref={ref}
        animate={controls}
        initial="hidden"
        // variants={containerVariant}
      >
        <Item variants={item}>
          <h3 style={{ marginTop: 0 }}>{header}</h3>
          <Title>{title}</Title>
          <BodyText>{subText}</BodyText>
        </Item>

        <Item type="img" variants={item}>
          <img
            src={src}
            style={{
              width: imgSize,
              margin: "auto",
            }}
            alt={header}
          />
        </Item>
      </Container>
      {!isLast && scrollPosition.width > 768 && (
        <div style={{ height: "15vw", position: "relative" }}>
          {/* <Lines isDefault={true} isReverse={isReverse} /> */}
          <LinesAnimated
            isReverse={isReverse}
            scrollPosition={scrollPosition}
          />
        </div>
      )}
    </>
  );
};

export default SectionItem;
