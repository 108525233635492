import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import grain from '../../assets/grain-gradient.png'

const Body = styled(motion.div)`
  color: #d0d0d0;
  font-size: 1.2em;
  font-weight: 600;
  font-family: 'archiathin', sans-serif;
  text-transform: lowercase;
  text-decoration: none;
  position: relative;
  margin: 0 1em;
  cursor: pointer;
`

const Underline = styled(motion.div)`
  width: 50px;
  height: 4px;
  background-image: url(${grain});
  background-size: cover;
  position: absolute;
  bottom: -13px;
`

const AnimatedLink = ({path, body, hover, hoverEnd, selected, onClick}) => {
  return (
    <Body
      animate
      key={path}
      onClick={onClick}
      onHoverStart={hover}
      onHoverEnd={hoverEnd}
      >
        {body}
        {path === selected && (
          <Underline layoutId="underline" />
        )}
    </Body>
  )
}

export default AnimatedLink
