import React from "react";
import { PageContainer } from "../../shared/Refactor/Containers";
import { BodyText, H2Styled, H3Title, Highlight } from "../../shared/Headers";
import { SectionDefault as Section } from "../../shared/Refactor/Sections";
import { GridThree } from "../../shared/Refactor/Lists";
import { StackedButton } from "../Buttons/StackedButton";
import styled from "styled-components";

const Button = styled(StackedButton)`
  margin: auto;
  width: 200px;
  margin-bottom: 50px;
  > div {
    width: 200px;
    display: flex;
    justify-content: center;
  }
`;
const Header = styled(H2Styled)`
  text-align: center;
  margin-bottom: 0.6em;
`;

const Container = styled(GridThree)`
  max-width: 75%;
  margin: 0 auto;
  padding: 20px 0;
`;

const Title = styled(H3Title)`
  color: #fff;
  font: calc(1em + 1.5vmin) archiaregular;
  text-transform: none;
  margin: 0 auto;
  z-index: 10;
  text-shadow: 2px 3px 5px rgb(0 0 0 / 50%);
  text-align: inherit;
  margin-bottom: 1em;
  color: #fff;
  padding-right: 20px;
  text-align: center;
  width: auto;
`;

const Body = styled(BodyText)`
  margin: 0 auto 1em;
  text-align: center;
  width: 100%;
  display; block;
  @media (min-width: 768px){
    width: 55%;
  }
`;
const Governance = () => {
  return (
    <PageContainer>
      <Section>
        <Header>Governance</Header>
        <Title>You're in control</Title>
        <Body>
          The APY Governance token grants voting and management power to token
          holders and puts the power of decentralized finance into the hands of
          the community.
          Some examples of what the APY Governance token allows token holders to
          do includes making changes to the protocol and deciding what farms are
          mined.{" "}
        </Body>
      </Section>
      <Section>
        <Header>
          Start <Highlight>Now</Highlight>
        </Header>
        <Container>
          <Button link="https://gv5qt5b8im5.typeform.com/to/Y8czQa3E"><p>Propose New Farm </p></Button>
          <Button link="https://forum.apy.finance/"><p>Discussion Forum</p></Button>
          <Button link="https://snapshot.org/#/apy.eth"><p>Vote on Proposals</p></Button>
        </Container>
      </Section>
    </PageContainer>
  );
};

export default Governance;
