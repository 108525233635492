import React from "react";
import styled from "styled-components";
import { useMedia, useMediaLayout } from "use-media";

import { StackedButton } from "../Buttons/StackedButton";
import arrow from "../../assets/right-arrow.svg";
import { GAevent } from "../../utils/Analytics";
import { ImgLink as Link } from "../../shared/Links";
import { SectionHero } from "../../shared/Refactor/Sections";
import { LINKS } from "../../constants/links";
import { ContainerGradient } from "../../shared/Refactor/Containers";
import Video from "./Video";
import { HeroSlider } from "./HeroSlider";

const Body = styled.p`
  color: #fff;
  font: calc(1em + 1.5vmin) archiaregular;
  text-transform: none;
  margin: 0 auto;
  z-index: 10;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: inherit;
  @media (min-width: 360px) {
    ${"" /* font-size: 20px; */}
  }
`;

const Header = styled.h1`
  margin: 0 0 0.5em 0;
  text-transform: none;
  color: #fff;
  font-size: calc(1em + 6vmin);
  text-transform: center;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  font-family: archiaregular;
  z-index: 10;
  width: fit-content;
  ${
    "" /* @media (min-width: 360px) {
    font-size: 38px;
  } */
  }
`;

const Arrow = styled.div`
  height: 30px;
  width: 30px;
  background-image: url(${arrow});
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  > :nth-child(2) {
    margin-top: 15px;
    > a {
      height: fit-content;
      width: fit-content;
      img {
        height: 25px;
      }
    }
  }
`;

const Button = styled(StackedButton)`
  ${"" /* margin: 0 5px 0 15px; */}
  margin: 1.25em 0 .75em;
  > div {
    margin-left: 0px;
    margin-right: 0;
  }
  > button {
    > p {
      font-size: 16px;
    }
  }
`;

const Container = styled(ContainerGradient)`
  // padding: 0 4em;
  margin-top: 1em;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  background: transparent;
  @media (min-width: 1025px) {
    justify-content: center;
    max-width: 1400px;
    width: calc(100% - 20vmin);
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    // left: 0;
    // transform: translate(-50%);
    padding: 0 10vmin;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
  }
`;

const Section = styled(SectionHero)`
  position: relative;
  padding-top: 65%;
  // max-height: calc(90vh - 50.25%);
  height: 100%;
`;

const Hero = () => {
  const isWide = useMediaLayout({ minWidth: 768 });
  return (
    <Section>
      {isWide && <Video />}
      <Container>
        <HeroSlider />
      </Container>
    </Section>
  );
};

export default Hero;
