import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";

export const H1Styled = styled.h1`
  color: #fff;
  margin-left: 1em;
  margin-bottom: 1.5em;
  font-size: 3em;
  text-transform: lowercase;
  font-family: "archiabold";
  padding: 0.25em 1em;
  width: fit-content;
`;

export const H2Heading = styled.h2`
  color: #f1f1f6;
  margin-left: 1em;
  margin-bottom: 1.5em;
  font-size: 3em;
  margin: 0;
  text-transform: lowercase;
  font-family: archiamedium;
  padding: 0.25em 1em;
  width: 30%;
  @media (max-width: 415px) {
    font-size: 1.5em;
    width: 80%;
    padding-left: 0.5em;
  }
`;

export const H2Styled = styled.h2`
  color: ${(props) => props.color};
  margin-left: 0;
  text-transform: lowercase;
  font: 65px archiaregular;
  width: fit-content;
  padding: 0.25em 0;
`;

export const H3Styled = styled.h3`
  color: #fff;
  margin-left: 1em;
  margin-bottom: 1.5em;
  font-size: 3em;
  text-transform: lowercase;
  font-family: archiabold;
  padding: 0.25em 1em;
  width: fit-content;
`;

export const H3Title = styled.h3`
  position: relative;
  text-transform: lowercase;
  padding-top: 0.5em;
  padding-left: 1.25rem;
  font-family: archiamedium;
  margin-top: 0;
  width: 100%;
  color: #f1f1f6;
  font-size: calc(0.4em + 0.4vmin);
`;

export const H4Title = styled.h4`
  position: relative;
  font-size: 1.2em;
  text-transform: lowercase;
  margin: 0;
  font-family: archiamedium;
  margin-top: 0;
  color: #f1f1f6;
  @media (max-width: 321px) {
    font-size: 14px;
  }
`;

export const PStyled = styled.p`
  padding: 0 1.25em 1.25em;
  text-align: left;
  font-family: archiaregular;
  color: #f1f1f6;
  font-size: calc(0.5em + 0.5vmin);
`;

export const Highlight = styled.span`
  color: #f1f1f6;
  padding: 0;
  font-family: archiasemibold;
  background-image: linear-gradient(to right top, #d153f4, #c753f4, #bc52f5, #b152f5, #a552f5, #9a51f3, #8f4ff2, #844ef0, #784bec, #6a48e8, #5c46e3, #4d43df);
  ${(props) =>
    props.bg === "dark" &&
    css`
      color: #f1f1f6;
      background-image: none;
      background-color: #202020;
    `}
  ${(props) =>
    props.bg === "light" &&
    css`
      color: #f1f1f6;
      background-image: none;
      background-color: #f1f1f6;
      color: #202020;
    `}
  ${(props) =>
    props.bg === "outline" &&
    css`
      color: hsl(0, 0%, 15%);
      background-image: none;
      background-color: #f1f1f6;
      font-family: archiasemibold;
    `}
`;
