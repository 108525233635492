import React, { useEffect } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { PROTOCOLS } from "../constants/protocols";
import { container, item, tap, hover } from "../constants/variants";
import { Grid } from "../shared/Refactor/Lists";
import { SectionGrid } from "../shared/Refactor/Sections";
import { ImgLink } from "../shared/Links";
import { Highlight, H2Styled as Header } from "../shared/Headers";

const Body = styled.p`
  color: #fff;
  font: calc(1em + 1.5vmin) archiaregular;
  text-transform: none;
  margin: 0 auto;
  z-index: 10;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: inherit;
  margin-bottom: 1.5em;
  color: #fff;
  padding-right: 20px;
  @media (min-width: 360px) {
    padding-right: 40px;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 65%;
  }
`;

const Section = styled(SectionGrid)`
  @media (min-width: 1025px) {
    padding: calc(10vh + 5vmin) 4em calc(20vh + 5vmin);
  }
  @media (min-width: 1280px) {
    padding: calc(10vh + 5vmin) 7em calc(20vh + 5vmin);
  }
`;

const ListItem = styled(motion.li)`
  width: 40px;
  height: 40px;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  @media (min-width: 360px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 768px) {
    width: 65px;
    height: 65px;
  }
  @media (min-width: 1280px) {
    width: 100px;
    height: 100px;
  }
`;

const Container = styled(Grid)`
  grid-gap: 2em 0;
  grid-template-columns: repeat(3, auto);
  padding: 0;
  @media (min-width: 500px) {
    grid-template-columns: repeat(4, auto);
    grid-gap: 3em;
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, auto);
  }
`;

const Protocol = ({ link, img, title, dimensions, variants }) => {
  return (
    <ListItem
      variants={item}
      key={title}
      whileTap={tap}
      whileHover={hover}
      title={title}
    >
      <ImgLink
        link={link}
        title={title}
        dimensions={{ height: "100%", width: "100%", margin: "0" }}
        img={img}
      />
    </ListItem>
  );
};

const Protocols = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Section hash="strategies">
      <TextContainer>
        <Header>
          Active <br /> <Highlight>Convex Portfolio</Highlight>
        </Header>
        <Body>A single deposit generates yield from Convex positions, the highest quality yield sources in the Curve ecosystem.</Body>
      </TextContainer>
      <Container
        ref={ref}
        variants={container}
        initial="hidden"
        animate={controls}
      >
        {PROTOCOLS.map((protocol, idx) => {
          return (
            <Protocol
              key={idx}
              img={protocol.logo}
              link={protocol.link}
              title={protocol.name}
            />
          );
        })}
      </Container>
    </Section>
  );
};

export default Protocols;
