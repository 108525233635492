import React from "react";
import styled from "styled-components";
import mesh from '../../assets/graphics/GridBackground.png';

const Container =styled.div`
  height: 40vw;
  min-height: 400px;
  background-position: center;
  background-size: cover;
  background-image: url(${mesh});
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0%;


  @media (min-width: 768px){
    background-size: 100%;
    top: 0%;
  }
`
const HeroMesh = () => {
  return <div style={{paddingBottom: "80px"}}><Container/> </div>;
};

export default HeroMesh
