import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { keyframes } from "styled-components";
import { animate, AnimateSharedLayout } from "framer-motion";

import { motion } from "framer-motion";

import { useState } from "react";
import { SectionDefault } from "../../shared/Refactor/Sections";
import { H2Styled } from "../../shared/Headers";
import axios from "axios";

const Body = styled(motion.div)`
  color: #d0d0d0;
  font-size: 1em;
  font-weight: 600;
  font-family: "archiathin", sans-serif;
  text-transform: lowercase;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  height: 100%;
`;

const Header = styled(H2Styled)`
  margin-bottom: 0;
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
  }
`;

const Line = styled(motion.div)`
  height: calc(100% + 10px);
  width: 2px;
  background-color: #844ef0;
  position: absolute;
  ${(props) => (props.isLast ? `bottom: 0px;` : `top: 0px;`)}
  right: ${(props) => (props.path ? -17 : -18)}px;
  top: ${(props) => (props.path > 0 ? -10 : 0)}px;
`;

const SliderPagination = styled.ul`
  display: flex;
  flex-direction: column;
  width: auto;
  top: 105px;

  @media (min-width: 768px) {
    top: 60px;
  }
  > li {
    margin: 0;
    height: 40px;
    width: 35px;
    text-align: left;
    border-right: 2px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    &:first-child {
      align-items: flex-start;
    }
    &:last-child {
      align-items: flex-end;
    }
  }
`;
const FadeIn = keyframes`
  from {
    transform: scale(.85);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const FadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(.85);
    opacity: 0;
  }
`;
const Slide = styled.div`
  padding: 0 0 0 50px;
  height: 100%;
  text-align: center;
  width: calc(100% - 50px) !important;

  @media (min-width: 768px) {
    padding: 0 50px;
    width: calc(100% - 100px) !important;
  }
`;

const SliderContainer = styled(Slider)`
  width: 100%;
  height: 50%;
  padding-top: 50px;

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

  }
  .slick-track,.slick-list {
      transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
  }
  


  .slick-slide {
    -webkit-backface-visibility: hidden;
    opacity: 0;
    animation: ${FadeOut} 0.85s forwards;

    &.slick-active {
      animation: ${FadeIn} 1s forwards cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
`;

const Section = styled(SectionDefault)`
  height: 100%;
  color: #fff;
  font-weight: 700;
  min-height: 250px;
  padding: 0 0.4em 1em;
  margin-top: 50px;
  @media (min-width: 768px) {
    margin-top: 0;
    padding: 0 2em 1em;
  }
  @media (min-width: 992px) {
    padding: 0 4em 1em;
  }
`;

const HighlightText = styled.span`
  color: #b454f1;
`;

const AnimatedLine = ({ path, body, hover, selected, hoverEnd, isLast }) => {
  return (
    <Body
      animate
      key={path}
      onHoverStart={hover}
      onHoverEnd={hoverEnd}
      isLast={isLast}
    >
      {body}
      {path === selected && <Line path={path} layoutId="line" />}
    </Body>
  );
};

const Counter = ({ from, to, duration = 0.75 }) => {
  const nodeRef = useRef();
  useEffect(() => {
    const node = nodeRef.current;
    const controls = animate(from, to, {
      duration,
      onUpdate(value) {
        let dollarUSLocale = Intl.NumberFormat("en-US");
        node.textContent = dollarUSLocale.format(value.toFixed(0));
      },
    });
    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
};

export const HeroSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [hoverSlide, setHoverSlide] = useState(0);
  const [info, setInfo] = useState({
    savedCosts: 4000000,
    tvl: 11000000,
    yield: 0.1,
    strategies: 3,
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3500,
    useTransform: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    mobileFirst: true,
    adaptiveHeight: true,
    draggable: true,
    // variableWidth: true,
    beforeChange: (current, next) => {
      setActiveSlide(next);
      setHoverSlide(next);
    },
    appendDots: (dots) => {
      return (
        <SliderPagination>
          <AnimateSharedLayout>{dots}</AnimateSharedLayout>
        </SliderPagination>
      );
    },
    customPaging: (i) => {
      return (
        <div>
          <AnimatedLine
            body={i < 9 ? "0" + (i + 1) : i + 1}
            selected={hoverSlide}
            isLast={i === 2}
            path={i}
            total={3}
            hover={() => setHoverSlide(i)}
            hoverEnd={() => setHoverSlide(activeSlide)}
          />
        </div>
      );
    },
  };

  const fetchInfo = () => {
    axios("https://service.apy.finance/v1/platform").then((res) => {
      setInfo(res.data);
    });
  };

  useEffect(() => {
    fetchInfo();
    return () => {
      setInfo({});
    };
  }, []);

  return (
    <SliderContainer {...settings}>
      <Slide>
        <Section>
          <Header>
            A single deposit generates yield from{" "}
            <HighlightText>
              <Counter from={5} to={parseInt(info?.strategies)} />{" "}
            </HighlightText>{" "}
            strategies
          </Header>
        </Section>
      </Slide>
      <Slide>
        <Section>
          <Header>
            Over{" "}
            <HighlightText>
              $<Counter from={10000000} to={parseInt(info?.savedCosts)}/>
            </HighlightText>{" "}
            in transaction costs saved
          </Header>
        </Section>
      </Slide>
    </SliderContainer>
  );
};
