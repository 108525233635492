import React, { useEffect } from 'react'
import styled from 'styled-components'

import { SectionDefault } from '../shared/Refactor/Sections'
import { H2Styled, H3Title } from '../shared/Headers'
import { GApageView } from '../utils/Analytics'
import { PRIVACY } from '../constants/privacy'

const Section = styled(SectionDefault)`
  height: fit-content;
  padding: 3em 5em;
  color: #d0d0d0;
  font-family: archiaregular;
  p > span {
    font-family: archiabold;
  }
  p > a {
    text-decoration: none;
    pointer: cursor;
    border-bottom: 1px solid #d0d0d0;
    &:visited {
      color: #d0d0d0;
    }
  }
  p > ul {
    margin: .7rem auto;
    list-style: circle !important;
    list-style-type: circle;
    list-style-position: inside !important;
  }
`

const Title = styled(H3Title)`
  margin-left: 0;
  padding-left: 0;
  text-transform: none;
`

const Header = styled(H2Styled)`
  padding: 0;
  margin-left: 0;
  margin-bottom: 0;
`

const Privacy = () => {
  useEffect(() => { GApageView("privacy"); }, []);

  return (
    <Section>
      <Header>Privacy Policy</Header>
      {PRIVACY.map((item, idx) =>
        <div key={`privacy item no. ${idx}`}>
          <Title>{item.title}</Title>
          <div>{item.content}</div>
        </div>
      )}
    </Section>
  )
}

export default Privacy
