import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  width: 100%;
  // height: 100vh;
  padding-top: 56.25%;
  // overflow: hidden;
  position: absolute;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  > video {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  @media (min-width: 1025px){
    display: block;
    margin-top: -80px;

  }
  @media (min-width: 1200px){
    display: block;
    margin-top: -4%;
  }


`;

const Video = () => {
  return (
    <VideoContainer>
      <video
        muted
        preload="auto"
        loop={true}
        src="https://firebasestorage.googleapis.com/v0/b/continual-grin-289318.appspot.com/o/apy-frontend-images%2Fhero_video.mp4?alt=media&token=a0f6dc0a-1f2b-40fe-b045-6dae2d2536f9"
        autoPlay={true}
      />
      {/* <Container>
            <Logo />
          </Container> */}
    </VideoContainer>
  );
};

export default Video;
