import React from "react";
import styled from "styled-components";

import { NotchedDiv } from "../../shared/NotchedDiv";
import gradient from "../../assets/graphics/gradient.svg";
import { COLORS } from "../../constants/colors";

const Wrap = styled.li`
  width: 100%;
  list-style: none;
`;

const Container = styled(NotchedDiv)`
  & > div:first-child {
    width: 60%;
    height: 60%;
  }
  & > div:nth-child(2) {
    background-color: ${COLORS.PAGE_BG};
  }
`;

const Image = styled.div`
  height: 190px;
  margin: 0 auto 10px;
  display: block;
  width: 216px;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    background: url(${gradient});
    opacity: 0.2;
    background-position: center;
    background-size: cover;
  }
  @media (min-width: 320px) {
    // height: calc(100% / 1.3);
    width: calc(100vw - 6.5em);
  }
  @media (min-width: 360px) {
    width: calc(100vw - 8em);
  }
  @media (min-width: 375px) {
    width: calc(100vw - 9em);
  }
  @media (min-width: 425px) {
    width: calc(100vw - 10em);
  }
  @media (min-width: 425px) {
    width: calc(100vw - 10em);
  }
  @media (min-width: 500px) {
    width: calc(45vw - 3em);
  }
  @media (min-width: 768px) {
    width: calc(200px + 2vmin);
  }
  @media (min-width: 1024px) {
    width: calc(230px + 2vmin);
  }
`;

const Name = styled.h3`
  color: #f1f1f6;
  font: calc(0.7em + 0.5vmin) archiamedium;
  margin: 0;
  margin-left: 10px;
  width: fit-content;
  text-align: left;
`;

const Title = styled.h4`
  color: #f1f1f6;
  padding: 5px 0 0;
  margin: 0;
  margin-left: 10px;
  font: calc(0.65em + 0.5vmin) archiaregular;
  text-align: left;
  width: fit-content;
`;

const Description = styled.div`
  padding: 0.75em 0.1em;
`;

const Member = ({ img, name, title }) => {
  const TitleWrap = ({ name, title }) => {
    return (
      <Container>
        <Description>
          <Name>{name}</Name>
          {title.map((position, idx) => (
            <Title key={idx}>{position}</Title>
          ))}
        </Description>
      </Container>
    );
  };

  return (
    <Wrap>
      <Image background={img} title={name} role="img" aria-label={name} />
      <TitleWrap name={name} title={title} />
    </Wrap>
  );
};

export default Member;
