import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { LinkTypes as Link } from '../../shared/Links'

const LinkText = styled.p`
  font-size: 1.5em;
`

const MenuLink = ({body, path, type, children, onClick}) => {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}>
      <Link
        link={path}
        type={type}
        key={body}
        onClick={onClick}>
        <LinkText>{children}</LinkText>
      </Link>
    </motion.li>
  )
}

export default MenuLink
