import React from "react";
import styled from "styled-components";
import HeroMesh from "../../components/Hero/HeroMesh";
import { COLORS } from "../../constants/colors";

export const ContainerDefault = styled.div`
  max-width: 2400px;
`;

export const ContainerHero = styled.div`
  @media (min-width: 1280px) {
    // padding: 3em 7em;
  }
`;

export const ContainerGrid = styled.div`
  display: flex;
  max-width: 2400px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media (min-width: 950px) {
    flex-direction: row;
  }
`;

export const ContainerGradient = styled.div`
  // background-color: ${COLORS.PAGE_BG};
  padding: 3em 1em;
  // @media (min-width: 320px) {
  //   padding: 3em 1em;
  // }
  // @media (min-width: 360px) {
  //   padding: 3em 1em;
  // }
  // @media (min-width: 375px) {
  //   padding: 3em 2em;
  // }
  // @media (min-width: 425px) {
  //   padding: 3em 2em;
  // }
  @media (min-width: 500px) {
    padding: 3em 2.5em;
  }
  @media (min-width: 768px) {
    padding: 3em 3em;
  }
  @media (min-width: 1025px) {
    padding: 3em 4em;
  }
  @media (min-width: 1280px) {
    padding: 15vmin 7em;
  }
`;


export const PageContainer = ({children}) => {
  return <div>
    <HeroMesh />
    {children}
  </div>
}