export const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2
    }
  }
};

export const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

export const hover = {
  scale: 1.01,
  y: '-.25rem',
  transition: {
    type: 'spring'
  }
}

export const tap = {
  scale: 0.98,
  transition: {
    type: 'spring'
  }
}

export const flex = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      staggerChildren: 0.5,
      ease: [0.02, 0.6, -0.01, 0.91]
    }
  }
};

export const flexItem = {
  hidden: {
    opacity: 0,
    y: 100
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      ease: [0.02, 0.6, -0.01, 0.91]
    }
  }
};
