import React from "react";
import { H3Title as Title, SubText } from "../shared/Headers";

// Feature Icons
import step1 from "../assets/features/STEP1.png";
import step2 from "../assets/features/STEP2.png";
import step3 from "../assets/features/STEP3.png";
import step4 from "../assets/features/STEP4.png";

// Press Mentions
import forbes from "../assets/press/forbes-bw.png";
import coinTelegraph from "../assets/press/ct-bw.png";
import coinDesk from "../assets/press/coindesk.png";

export const FEATURES = [
  {
    title: "All stablecoin deposits are pooled",
    body: "Pooled liquidity allows for batched transactions, saving every user time and gas-fees.",
    img: step1,
    header: "Step one",
    imgSize: "80%",
    imgSizeMB: "80%",
    subText: "Pooled liquidity allows for smart transactions and access to complex yield farming strategies."
  },
  {
    title: "The pool accesses Curve and Convex yields",
    body: "A user instantly gains exposure to the highest quality yield sources in the Curve and Convex ecosystem.",
    img: step2,
    header: "Step two",
    imgSize: "40%",
    imgSizeMB: "50%",
    subText: "Instantly, the user deposit earns the same yield as the rest of the pool."
  },
  {
    title: "Returns are autocompounded and rebalanced",
    body: `Periodic portfolio compounding and rebalancing allows a user to continuously earn, hands-off.`,
    img: step3,
    header: "Step three",
    imgSize: "70%",
    imgSizeMB: "80%",
    subText: "All earned rewards from various farming strategies are claimed and redeployed."
  },
  // {
  //   title: "Governance adds new pools",
  //   body: `User shares are repriced as yield accrues powered by a proprietary oracle system.`,
  //   img: step4,
  //   header: "Step four",
  //   imgSize: "70%",
  //   imgSizeMB: "80%",
  //   subText: "APY.Finance harnesses the the power of decentralized finance to deploy funds to innovative, high performing funds."
  // },
];

export const PRESS = [
  {
    source: "coin telegraph",
    title: "Yield farming platform APY Finance locks $67 million in first hour",
    date: "OCT 02, 2020",
    link: "https://cointelegraph.com/news/yield-farming-platform-apy-finance-locks-67-million-in-first-hour",
    logo: coinTelegraph,
    snippet:
      "APY.Finance, a yet-to-launch decentralized finance (DeFi) yield farming aggregator, announced Monday it has completed a $3.6M seed funding round joined by investors including Arrington XRP Capital, Alameda Research, Cluster Capital and CoinGecko.",
  },
  {
    source: "coindesk",
    title:
      "DeFi Yield Farming Aggregator APY.Finance Raises $3.6M in seed funding",
    date: "Sep 21, 2020",
    link: "https://www.coindesk.com/defi-yield-farming-aggregator-apy-finance-raises-3-6m-in-seed-funding",
    logo: coinDesk,
    snippet:
      "APY.Finance, a yet-to-launch decentralized finance (DeFi) yield farming aggregator, announced Monday it has completed a $3.6M seed funding round joined by investors including Arrington XRP Capital, Alameda Research, Cluster Capital and CoinGecko.",
  },
  {
    source: "forbes",
    title: "Three Things You May Not Know About The DeFi Market",
    date: "Feb 18, 2021",
    link: "https://www.forbes.com/sites/forbescommunicationscouncil/2021/02/18/three-things-you-may-not-know-about-the-defi-market/?sh=1fc23615f01e",
    logo: forbes,
    snippet:
      "APY.Finance, a yet-to-launch decentralized finance (DeFi) yield farming aggregator, announced Monday it has completed a $3.6M seed funding round joined by investors including Arrington XRP Capital, Alameda Research, Cluster Capital and CoinGecko.",
  },
];

export const INFO = [
  {
    title: <Title>Hands-off Yield Farming</Title>,
    body: "Just deposit, and earn the same yield no matter what tokens you use. APY.Finance makes all of the moves for you.",
    // icon: pooled_liquidity,
  },
  {
    title: <Title>Diversified Portfolio</Title>,
    body: "Diversification is the most important tool for reducing risk. Until APY.Finance, gas fees made diversification impossible for your average yield farmer.",
    // icon: pooled_liquidity,
  },
  {
    title: <Title>DAI, USDC, & USDT</Title>,
    body: "APY.Finance supports deposits from all major stablecoins. The platform uses your deposits to farm yield with a diversified portfolio optimized to reduce risk.",
    // icon: pooled_liquidity,
  },
];
