import React from "react";
import {
  ContainerDefault,
  ContainerHero,
  ContainerGradient,
  ContainerGrid,
} from "./Containers";
import { Wrap, WrapHero, WrapMesh, WrapGradient } from "./Wraps";

export const SectionDefault = ({ hash, className, children }) => {
  return (
    <Wrap id={hash} className={className}>
      <ContainerDefault>{children}</ContainerDefault>
    </Wrap>
  );
};

export const SectionGradient = ({ hash, className, children }) => {
  return (
    <WrapGradient id={hash} className={className}>
      <ContainerGradient>{children}</ContainerGradient>
    </WrapGradient>
  );
};

export const SectionMesh = ({ hash, size, className, children }) => {
  return (
    <WrapMesh id={hash} size={size} className={className}>
      <ContainerDefault size={size}>{children}</ContainerDefault>
    </WrapMesh>
  );
};

export const SectionGrid = ({ hash, className, children }) => {
  return (
    <Wrap id={hash} className={className}>
      <ContainerGrid>{children}</ContainerGrid>
    </Wrap>
  );
};

export const SectionHero = ({ children }) => {
  return (
    <WrapHero>
      <ContainerHero>{children}</ContainerHero>
    </WrapHero>
  );
};
