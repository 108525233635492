import React from "react";
import styled from "styled-components";

import { PStyled } from "../shared/TextContent";
import { NotificationButton as Button } from "./Buttons/NotificationButton";

const Wrap = styled.div`
  -webkit-backdrop-filter: blur(15px);
  align-items: center;
  backdrop-filter: blur(15px);
  background: rgba(32, 32, 32, 0.65);
  border: 1px solid rgba(32, 32, 32, 0.8);
  border-radius: 15px;
  bottom: 2em;
  box-shadow: hsla(0, 0%, 9%, 0.12) 0px 2px 4px 0px,
    hsla(0, 0%, 9%, 0.32) 0px 2px 16px 0px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: space-between;
  padding: 1.5em;
  position: fixed;
  // right: 9%;
  width: 80%;
  z-index: 200;
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  
`;

const Description = styled(PStyled)`
  margin: 0;
  padding: 0;
  font-family: archiamedium;
  font-size: 1em;
  width: fit-content;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    margin: 0;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const CookiesConsent = ({ setConsent, setSeen }) => {
  const consent = () => {
    setConsent(true);
    setSeen(true);
  };

  return (
    <Wrap>
      <Description>
        We use cookies to make your experience better. You can read more in our
        pivacy policy to learn about how we use cookies.
      </Description>
      <Group>
        <Button fn={() => setSeen(true)} action="cancel">
          dismiss
        </Button>
        <Button fn={() => consent()} action="accept">
          accept
        </Button>
      </Group>
    </Wrap>
  );
};

export default CookiesConsent;
