import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";

import { INVESTORS } from "../constants/investors";
import { container, item, tap, hover } from "../constants/variants";
import { Highlight, H2Styled as Header } from "../shared/Headers";
import { ImgLink } from "../shared/Links";
import { Grid } from "../shared/Refactor/Lists";
import { SectionMesh as Section } from "../shared/Refactor/Sections";

const Container = styled(Grid)`
  grid-gap: 2em;
  @media (min-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr)
  }
`;

const ListItem = styled(motion.li)`
  height: calc(100% * 1.1);
  list-style-type: none;
  position: relative;
  width: 100%;
  &::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 5px;
    left: 5px;
  }
`;

const Investor = ({ link, img, title, dimensions, variants }) => {
  return (
    <ListItem
      variants={item}
      key={title}
      whileTap={tap}
      whileHover={hover}
      title={title}
    >
      <ImgLink
        link={link}
        title={title}
        dimensions={{ height: "100%", width: "100%", margin: "0" }}
        img={img}
      />
    </ListItem>
  );
};

const Investors = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <Section hash="investors" >
      <Header>
        Our Valued
        <br />
        <Highlight >Investors</Highlight>
      </Header>
      <Container
        ref={ref}
        variants={container}
        initial="hidden"
        animate={controls}
      >
        {INVESTORS.map((investor, idx) => {
          return (
            <Investor
              key={idx}
              link={investor.link}
              img={investor.image}
              title={investor.name}
            />
          );
        })}
      </Container>
    </Section>
  );
};

export default Investors;
