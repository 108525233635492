import React from 'react'
import ReactDOM from 'react-dom';
import styled from 'styled-components'
import { motion } from 'framer-motion'
import wave from '../../assets/wave-test.svg'
import Community from '../Footer/Community'
import MenuLink from './MenuLink'
import { LINKS } from '../../constants/links'

const Wrap = styled(motion.section)`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: block;
  background: rgba( 32, 32, 32, 0.65 );
  box-shadow: hsla(0, 0%, 9%, 0.12) 0px 2px 4px 0px, hsla(0, 0%, 9%, 0.32) 0px 2px 16px 0px;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  z-index: 100;
`

const Container = styled.div`
  height: 100%;
  width: 85%;
  position: absolute;
  background-image: url(${wave});
  background-size: cover;
  background-position: center;
  background-color: #161616;
  right: 0;
  display: grid;
  place-items: center;
`

const List = styled(motion.ul)`
  height: 70%;
  width: 80%;
  list-style-type: none;
`

const Menu = ({expanded, onClick}) => {
  const variants = {
      open: { opacity: 1, x: 0,
        transition: {
          type: 'spring',
          stiffness: 30,
          restDelta: 1,
          duration: 1
        },
      },
      closed: {
        opacity: 0,
        x: '100%',
        transition: {
          type: 'spring',
          stiffness: 400,
          damping: 40,
          duration: 10
        },
      },
    };

    const ulVariants = {
      open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
      },
      closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
      }
    }
  return (
    ReactDOM.createPortal(
        <Wrap
          variants={variants}
          initial={expanded ? 'closed' : 'open'}
          animate={expanded ? 'open' : 'closed'}
          exit={expanded ? 'closed' : 'open'}
          >
          <Container>
            <List
              variants={ulVariants}>
                {LINKS.navBar.map((link, idx) =>
                  <MenuLink
                    key={link.body}
                    type={link.type}
                    path={link.path}
                    onClick={onClick}
                  >
                    {link.body}
                  </MenuLink>
                )}
              </List>
              <Community key='community' />
          </Container>
        </Wrap>,
      document.getElementById('portal')
    )
  )
}

export default Menu
