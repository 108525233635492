import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import grain from '../assets/grain-gradient.png'
import { whiteWave } from '../constants/images'

const Wrap = styled(motion.div)`
  position: relative;
  width: 100%;
  height: fit-content;
`

const Content = styled.div`
  width: calc(100% - 7px);
  right: 0;
  top: 7px;
  left: 7px;
  height: fit-content;
  color: #d0d0d0;
  position: relative;
  z-index: 20;

`

const CornerNotch = styled.div`
  position: absolute;
  color: #d0d0d0;
  top: 0;
  background-image: url(${grain});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 60px;
`

const StackedWrap = styled(motion.li)`
  list-style-type: none;
  position: relative;
  height: 100%
  padding: 0;
  width: 100%;
  margin: 2em 0;
  @media (min-width: 768px) {
  }
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    top: 12px;
    left: 15px;
    position: absolute;
    z-index: 19;
    outline: 1px solid #f1f1f6;
    background-color: transparent;
    opacity: 1;
    background-image: url(${whiteWave});
    background-size: cover;
    @media (min-width: 768px) {
      width: 100%;
    }

  }
`

const StackedContent = styled(Content)`
  border: 1px solid #f1f1f6;
  background-color: #202020;
  box-shadow: hsla(0, 0%, 9%, 0.12) 0px 2px 4px 0px, hsla(0, 0%, 9%, 0.32) 0px 2px 16px 0px;
  width: 100%;
  // height: fit-content;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;

`

export const NotchedDiv = ({className, children}) => {
  return (
    <Wrap className={className}>
      <CornerNotch />
      <Content>
        {children}
      </Content>
    </Wrap>
  )
}

export const StackedDiv = ({className, children, ...props}) => {
  return (
    <StackedWrap
      {...props}
      className={className}>
      <StackedContent>
        {children}
      </StackedContent>
    </StackedWrap>
  )
}
