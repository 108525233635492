import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const HashLinkStyled = styled(HashLink)`
  color: #d0d0d0;
  font-family: archiaregular;
  text-decoration: none;
`;

export const RouterLinkStyled = styled(Link)`
  color: #d0d0d0;
  font-family: archiaregular;
  text-decoration: none;
`;

export const AnchorLinkStyled = styled(motion.a)`
  color: #d0d0d0;
  font-family: archiaregular;
  text-decoration: none;
  > p {
    text-transform: lowercase;
    margin: 0;
  }
`;

export const Img = styled.img`
  height: ${(props) => props?.dimensions?.height};
  width: ${(props) => props?.dimensions?.width};
  margin: ${(props) => props?.dimensions?.margin};
`;

export const ImgLink = ({ img, title, link, dimensions, ...props }) => {
  return (
    <AnchorLinkStyled
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      title={title}
      {...props}
    >
      <Img
        width="600"
        height="400"
        src={img}
        alt={title}
        dimensions={dimensions}
      />
    </AnchorLinkStyled>
  );
};

export const LinkTypes = ({ type, link, children, ...props }) => {
  switch (type) {
    case "anchor":
      return (
        <AnchorLinkStyled
          href={link}
          {...props}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </AnchorLinkStyled>
      );
    case "route":
      return <RouterLinkStyled to={link}  {...props}>{children}</RouterLinkStyled>;
    case "hash":
      return (
        <HashLinkStyled smooth to={link} {...props}>
          {children}
        </HashLinkStyled>
      );
    default:
      break;
  }
};
