import styled, { css } from "styled-components";
import * as IMAGES from "../../constants/images";

const BACKGROUNDS = {
  sm: IMAGES.smWave,
  lg: IMAGES.lgWave,
  mesh: IMAGES.mesh,
};

export const Wrap = styled.section`
  padding: 3em 1.5em 5em;
  position: relative;
  @media (min-width: 320px) {
    padding: 3em 1.5em 5em;
  }
  @media (min-width: 360px) {
    padding: 3em 1.5em 5em;
  }
  @media (min-width: 375px) {
    padding: 3em 1.5em;
  }
  @media (min-width: 425px) {
    padding: 3em 5em;
  }
  @media (min-width: 500px) {
    padding: 3em 3em;
  }
  @media (min-width: 768px) {
    padding: 3em 3em;
  }
  @media (min-width: 1024px) {
    padding: 3em 3em;
  }
  @media (min-width: 1280px) {
    padding: 3em 7em;
  }
`;

export const WrapHero = styled.div`
  background-image: url(${BACKGROUNDS.mesh});
  background-repeat: no-repeat;
  background-size: 500%;
  height: 100vh;
  margin-top: calc(10vh + 1em);
  position: relative;
  width: 100%;
  @media (min-width: 320px) {
    margin-top: calc(2em + 1.5vmin);
  }
  @media (min-width: 390px) {
    margin-top: calc(5em + 1.5vmin);
    background-size: 390%;
    height: 90vh;
  
  }
  @media (min-width: 600px) {
    margin-top: calc(5em + 1.5vmin);
    background-size: 200%;
  
  }
  @media (max-height: 600px) {
    background-size: 300%;
    margin-top: calc(5em + 1.5vmin);
  }
  @media (min-width: 1025px) {
    margin-top: calc(2em + 1.5vmin);
    background-image: none;
    padding-top: 70%;
    height: 0;
    margin-top: -3em;
  }
  @media (min-width: 1200px) {
    margin-top: calc(2em + 1.5vmin);
    background-image: none;
    padding-top: 65%;
    height: 0;
    margin-top: -100px;
  }
`;

export const WrapMesh = styled(Wrap)`
  ${(props) =>
    props.size === "sm" &&
    css`
      background-position: center;
      background-size: 200px;
      background-image: url(${(props) => BACKGROUNDS[props.size]});
      background-repeat: repeat;
    `}
  ${(props) =>
    props.size === "lg" &&
    css`
      background-position: center 10%;
      background-size: 800%;
      background-image: url(${(props) => BACKGROUNDS[props.size]});
      @media (min-width: 500px) {
        background-size: 120%;
      }
    `}
`;

export const WrapGradient = styled(Wrap)`
  padding: 10px 0 0 0;
  background-image: linear-gradient(
    to left top,
    hsla(286, 87%, 64%, 0.9),
    hsla(283, 87%, 64%, 0.9),
    hsla(279, 89%, 64%, 0.9),
    hsla(274, 89%, 64%, 0.9),
    hsla(270, 89%, 64%, 0.9),
    hsla(267, 88%, 63%, 0.9),
    hsla(263, 86%, 62%, 0.9),
    hsla(260, 84%, 62%, 0.9),
    hsla(256, 80%, 60%, 0.9),
    hsla(252, 77%, 59%, 0.9),
    hsla(248, 73%, 58%, 0.9),
    hsla(243, 70%, 56%, 0.9)
  );
`;
