import { useModalStore } from '../state/store';

export function useModal(modalKey) {
  // Zustand
  const isCortexModalOpen = useModalStore((state) => state.isCortexModalOpen);
  const setIsCortexModalOpen = useModalStore(
    (state) => state.setIsCortexModalOpen
  );

  const modalState = {
    cortex: {
      isOpen: isCortexModalOpen,
      setIsOpen: setIsCortexModalOpen,
    },
  };

  return modalState[modalKey];
}
