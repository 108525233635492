// Team Images
import chanho from "../assets/team/team-chanho.jpg";
import dina from "../assets/team/team-dina.jpg";
import jonathan from "../assets/team/team-jonathan.jpg";
import pascal from "../assets/team/team-pascal.jpg";
import sunil from "../assets/team/team-sunil.jpg";
import will from "../assets/team/team-will.jpg";
import jason from "../assets/team/team-jason.png";
import anthony from "../assets/team/anthony.jpg";
import architect from "../assets/team/the-architect.png";

export const TEAM = [
  {
    name: "Will Shahda",
    title: ["CEO & Solidity Engineer"],
    image: will,
  },
  {
    name: "Chan-Ho Suh",
    title: ["Solidity Engineer"],
    image: chanho,
  },
  {
    name: "The Architect",
    title: ["Solidity Engineer"],
    image: architect,
  },
  {
    name: "Jonathan Viray",
    title: ["Full Stack Engineer"],
    image: jonathan,
  },
  {
    name: "Jason Tissera",
    title: ["Product"],
    image: jason,
  },
  {
    name: "Anthony Cassarino",
    title: ["Marketing Manager"],
    image: anthony,
  },
];

export const ADVISORS = [
  {
    name: "Sunil Srivatsa",
    title: ["Advisor & Founder of Saddle"],
    image: sunil,
  },
  {
    name: "Pascal Tallarida",
    title: ["Advisor & Founder of Jarvis"],
    image: pascal,
  },
];
