import styled, { css } from "styled-components";

export const H1Styled = styled.h1`
  color: #fff;
  margin-left: 1em;
  margin-bottom: 1.5em;
  font-size: 3em;
  text-transform: lowercase;
  font-family: "archiabold";
  padding: 0.25em 1em;
  width: fit-content;
`;

export const H2Styled = styled.h2`
  color: #f1f1f6;
  margin: 0;
  font: calc(1em + 3.5vmin) archiaregular;
  width: 100%;
  margin-bottom: 1em;
  ${(props) =>
    props.color === "dark" &&
    css`
      color: #202020;
    `}
`;

export const H3Title = styled.h3`
  position: relative;
  font: calc(0.6em + 1vmin) archiaregular;
  padding-top: 0.5em;
  padding-left: 1.25rem;
  font-family: archiaregular;
  margin-top: 0;
  width: 100%;
  color: #f1f1f6;
  line-height: 1.25;
  @media (min-width: 768px) {
    width: 80%;
  }
`;

export const BodyText = styled.p`
  position: relative;
  font: 1.1em archiaregular;
  margin-top: 0;
  margin-bottom: 1em;
  color: #f1f1f6;
  line-height: 1.25;
  @media (min-width: 768px) {
    font: calc(0.6em + 1vmin) archiaregular;
  }
`;

export const SubText = styled.p`
  color: #fff;
  font: calc(1em + 1.5vmin) archiaregular;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: inherit;
  margin-bottom: 1em;
  margin-top: 0;
`;

export const Highlight = styled.span`
  color: #f1f1f6;
  padding: 0;
  font-family: archiasemibold;
  background-image: linear-gradient(
    to right top,
    #d153f4,
    #c753f4,
    #bc52f5,
    #b152f5,
    #a552f5,
    #9a51f3,
    #8f4ff2,
    #844ef0,
    #784bec,
    #6a48e8,
    #5c46e3,
    #4d43df
  );
  ${(props) =>
    props.bg === "light" &&
    css`
      color: #f1f1f6;
      background-image: none;
      background-color: #f1f1f6;
      color: #202020;
    `}
  ${(props) =>
    props.bg === "dark" &&
    css`
      background-image: none;
      background-color: #202020;
      color: #f1f1f6;
    `}
`;
