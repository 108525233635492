import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'styled-components'

import * as serviceWorker from './serviceWorker';


const theme = {
  breakpoints: {
    min: '281px',
    xxs: '0',
    'iphone-sm': '325px',
    xs: '376px',
    'iphone-lg': '414px',
    sm: '500px',
    md: '650px',
    'ipadMini': '650px',
    'tablet-md': '810px',
    'width900': '900px',
    'tablet-lg': '1026px',
    'width1100': '1100px',
    'laptop-sm': '1280px',
    lg: '1300px',
    xl: '1369px',
    xxl: '1500px',
    'mbp16in': '1750px',
    max: '1790px'
  }
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <App />
      </React.Fragment>
    </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
smoothscroll.polyfill();
serviceWorker.unregister();
