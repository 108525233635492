import ReactGA from 'react-ga'

// Cookie Logic
export const cookieStorage = {
    getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
    },
    setItem: (item, value) => {
        document.cookie = `${item}=${value};`
    }
}

export const GApageView = (page) => {
    cookieStorage && ReactGA.pageview(page);
}

export const GAevent = (categoryName, eventName) => {
  cookieStorage &&  ReactGA.event({
      category: categoryName,
      action: eventName,
      label: 'user action',
      value: 10,
      nonInteraction: false,
  });
}
