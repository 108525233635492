import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const Hamburglar = ({onClick, expanded}) => {

  const top = {
    closed: {
      rotate: 0,
      translateY: 0
    },
    opened: {
      rotate: 45,
      translateY: 9
    }
  };

  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
      x2:'10',
      x1: '0',
      translateX: 0
    },
    opened: {
      rotate: -45,
      translateY: -4,
      x2:'24',
      x1: '0',
      translateX: 2

    }
  };

  const mid = {
    closed: {
      opacity: 1,
      x1: 0,
      x2: 20
    },
    opened: {
      opacity: 0,
      x1:0,
      x2: 0
    }
  };

  const Line = (props) => (
    <motion.line
      fill="transparent"
      strokeWidth="1"
      stroke="#d0d0d0"
      strokeLinecap="round"
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
      vectorEffect="non-scaling-stroke"
      variants={props.variants}
      animate={expanded ? "opened" : "closed"}
      initial={expanded ? "closed" : "opened"}
      {...props}
    />
  );

  return (
      <div style={{
        cursor: "pointer",
        zIndex: 150,
        height: 24,
        width: 24,
        // border: '1px solid red'
      }}>
        <motion.svg
          viewBox='0 0 24 24'
          overflow="visible"
          width='24'
          height='24'
          preserveAspectRatio="none"
          role='button'
          onClick={onClick}>
            <AnimatePresence>
              <Line
                key='top'
                x1="0"
                x2='24'
                y1="2"
                y2="2"
                variants={top}
              />
              <Line
                x1="0"
                key='mid'
                x2='24'
                y1="11"
                y2="11"
                variants={mid}
              />
              <Line
                y1="20"
                y2="20"
                key='bottom'
                variants={bottom}
              />
            </AnimatePresence>
        </motion.svg>
      </div>
  )
}

export default Hamburglar
