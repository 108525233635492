import React from "react";
import styled from "styled-components";

import { SectionMesh } from "../../shared/Refactor/Sections";
import { Highlight, H2Styled } from "../../shared/Headers";
import { ImgLink } from '../../shared/Links';
import { GAevent } from '../../utils/Analytics'


import Form from "./Form";
import { LINKS } from "../../constants/links";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto ;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  > h2 {
    width: 90%;
    margin-bottom: 0.5em;
    @media (min-width: 768px) {
      width: 100%;
    }
  }
`;

const Header = styled(H2Styled)`
  text-align: center;
`

const Section = styled(SectionMesh)`
  margin-top: 7%;
`

const Link = styled(ImgLink)`
  img {
    height: 22px;
    width: auto;
    margin: 0 20px 0 0;
    @media (min-width: 768px) {
      height: 25px;
    }
  }
`;

const Newsletter = () => {
  const links = LINKS.community

  return (
    <Section size="sm" >
      <Container>
        <Header>
          Sign up for the <Highlight>newsletter</Highlight> 
        </Header>
        {/* <div>
          {links.map((link, idx) =>
            <Link
              onClick={() => GAevent('Social Media', `User navigated to ${link.name}`)}
              key={idx}
              dimensions={{ height: '25px', width: 'auto', margin: '0 10px' }}
              img={link.icon}
              title={link.name}
              link={link.link}
            />
          )}
        </div>
        <br /> */}
        <Form />
      </Container>
    </Section>
  );
};

export default Newsletter;
