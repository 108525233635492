// Investor Logos
import alameda from '../assets/logos/investors/resized/alameda.svg'
import arrington from '../assets/logos/investors/resized/arrington.svg'
import btc12 from '../assets/logos/investors/resized/btc12.svg'
import cluster from '../assets/logos/investors/resized/cluster.svg'
import coingecko from '../assets/logos/investors/resized/coingecko.svg'
import genblock from '../assets/logos/investors/resized/genblock.svg'
import rarestone from '../assets/logos/investors/resized/rarestone.svg'
import lao from '../assets/logos/investors/resized/the_lao.svg'
import trg from '../assets/logos/investors/resized/trgcapital.svg'
import vendetta from '../assets/logos/investors/resized/vendetta.svg'

export const INVESTORS = [
  {
    name: 'Alameda Research',
    image: alameda,
    link: 'https://www.alameda-research.com/'
  },
  {
    name: 'Arrington XRP Capital',
    image: arrington,
    link: 'http://arringtonxrpcapital.com/'
  },
  {
    name: 'BTC 12 Capital',
    image: btc12,
    link: 'https://btc12.com/'
  },
  {
    name: 'Cluster Capital',
    image: cluster,
    link: 'https://www.cluster.vc/'
  },
  {
    name: 'CoinGecko',
    image: coingecko,
    link: 'https://www.coingecko.com/'
  },
  {
    name: 'Genblock Capital',
    image: genblock,
    link: 'https://genblock.capital/'
  },
  {
    name: 'Rarestone Capital',
    image: rarestone,
    link: 'https://rarestone.capital/'
  },
  {
    name: 'The LAO',
    image: lao,
    link: 'https://www.thelao.io/'
  },
  {
    name: 'TRG Capital',
    image: trg,
    link: 'https://trgc.io/'
  },
  {
    name: 'Vendetta Capital',
    image: vendetta,
    link: 'https://www.vendetta.capital/'
  },
]
