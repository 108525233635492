import React, { useEffect } from "react";
import styled from "styled-components";

import Hero from "./Hero";
import Features from "./Features";
import Protocols from "./Protocols";

import { GApageView } from "../utils/Analytics";
import { SectionDefault } from "../shared/Refactor/Sections";

import { StackedButton } from "./Buttons/StackedButton";
import LaunchBackground from "./../assets/graphics/Launch-Background.png";
import { H2Styled } from "../shared/Headers";
const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

const Section = styled(SectionDefault)`
  padding-bottom: 1.2rem;
`;
const Header = styled(H2Styled)`
  position: absolute;
  top:10%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block
  color: #fff;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify: center;
  align-items: center;
  height: 40vw;
  min-height: 400px;
  background-position: center;
  background-size: 400%;
  background-image: url(${LaunchBackground});
  background-repeat: repeat;
  @media (min-width: 768px) {
    background-size: 100%;
  }
`;

const Button = styled(StackedButton)`
  margin: auto;
  width: 200px;
  > div {
    width: 200px;
    display: flex;
    justify-content: center;
  }
`;
const Launch = () => {
  return (
    <Section>
      <Header>See what APY can do</Header>
      <Container>
        <Button link={"https://dashboard.apy.finance/"}>
          <p>Launch</p>
        </Button>
      </Container>
    </Section>
  );
};
const Home = () => {
  useEffect(() => {
    GApageView("Home");
  }, []);

  return (
    <Main>
      <Hero />
      <Protocols />
      <Features />
      <Launch />
    </Main>
  );
};

export default Home;
