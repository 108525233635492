import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import {NotchedDiv} from '../../shared/NotchedDiv'
import { GAevent } from '../../utils/Analytics'
import rightArrow from '../../assets/right-arrow.svg'

const Wrap = styled.div`
  height: fit-content;
  width: 95%;
  padding: 5 0 10px;
  @media (min-width: 500px) {
    width: 80%
  }
  @media (min-width: 768px) {
    width: 60%
  }
`

const Container = styled(NotchedDiv)`
  height: 40px;
  width: 100%;
  @media (min-width: 360px) {
    height: 50px;
    width: 100%;
  }
  > div:nth-child(1) {
    width: 100%;
    height: 100%;
    top: 15px;
    left: 10px;
  }
  > div:nth-child(2) {
    height: 40px;
    width: 100%;
    left: 0;
    right: 10px;
    bottom: 10px;
    background-color: #d0d0d0;
    border: 2px solid #474747;
    @media (min-width: 360px) {
      height: 50px;
      width: 100%;
    }
  }
`

const FormStyled = styled.div`
  display: flex;
  flex-direction: row;
  color: #d0d0d0;
  position: relative;
  align-items: center;
  z-index: 10;
`

const Input = styled.input`
  background-color: transparent;
  color: #202020;
  padding-left: 2vw;
  font-family: archiaregular;
  height: 40px;
  line-height: 40px;
  font-size: 0.7em;
  width: 100%;
  border: none;
  outline: none;
  &::placeholder {
    color: hsla(0, 0%, 12%, 0.65);
    font-weight: 600;
    text-transform: lowercase;
    font-family: archiaregular;
  }
  @media (min-width: 360px) {
    width: 100%;
    line-height: 50px;
    font-size: 0.8em;
    height: 50px;
  }
`

const Submit = styled(motion.input)`
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  width: 40px;
  background-image: url(${rightArrow});
  background-position: center;
  color: transparent;
  background-size: 40%;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  @media (min-width: 360px) {
    height: 50px;
    width: 50px;
  }
`


const Form = () => {
  return (
    <Wrap>
      <Container>
          <form
            action="https://finance.us17.list-manage.com/subscribe/post?u=364c817c91a8f54732894b7c6&amp;id=4462c6d940"
            method="post"
            onSubmit={() => GAevent('Newsletter', 'User subscribed to the newsletter')}
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate>
              <div
                >
                <FormStyled
                  className="field-wrapper">
                  <Input
                    type="email"
                    defaultValue=""
                    name="EMAIL"
                    className="email-input"
                    placeholder="Your Email Address"
                    autoComplete="off"
                    required />
                  <div
                    style={{position: "absolute", left: "-5000px"}}
                    aria-hidden="true">
                    <input
                      type="text"
                      name="b_364c817c91a8f54732894b7c6_4462c6d940"
                      tabIndex="-1"
                      defaultValue=""
                    />
                  </div>
                  <div
                    className="clear">
                    <Submit
                      whileHover={{scale: 1.05}}
                      whileTap={{ scale: 0.99}}
                      type="submit"
                      name="subscribe"
                    />
                  </div>
                </FormStyled>
              </div>
          </form>
      </Container>
    </Wrap>
  )
}


export default Form
