import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { down } from "styled-breakpoints";

import { LINKS } from "../../constants/links";
import { GAevent } from "../../utils/Analytics";
import { ImgLink } from "../../shared/Links";

const Wrap = styled(motion.div)`
  // height: fit-content;
  // grid-area: links;
  // place-self: end;
  // width: fit-content;
  ${down("sm")} {
    place-self: center;
  }
  @media (min-width: 758px) {
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  ${down("sm")} {
    margin: 20px auto;
  }
  @media (min-width: 758px) {
    margin-top: 0;
  }
`;

const Link = styled(ImgLink)`
  img {
    height: 22px;
    width: auto;
    margin: 0 20px 0 0;
    @media (min-width: 768px) {
      height: 25px;
    }
  }
`;

const Community = () => {
  return (
    <Wrap>
      <Container>
        {LINKS.community.map((link, idx) => (
          <Link
            onClick={() =>
              GAevent("Social Media", `User navigated to ${link.name}`)
            }
            key={idx}
            dimensions={{
              height: "25px",
              width: "auto",
              margin: "0 20px 0 0 ",
            }}
            img={link.icon}
            title={link.name}
            link={link.link}
          />
        ))}
      </Container>
    </Wrap>
  );
};

export default Community;
