import React from 'react'
import styled from 'styled-components'

import { H3Title } from '../../shared/Headers'
import { LinkTypes as Link } from '../../shared/Links'
import { StackedDiv } from '../../shared/NotchedDiv'

const Title = styled(H3Title)`
  padding: 1em 0 0;
  font-size: 1.25em;
  flex-grow: 1;
  @media (min-width: 768px) {
    padding: 1.5em 0 0;
    font-size: 1.25em;
    width: 100%;
  }
 
`

const Description = styled.div`
  padding: 0 1.5em 1em;
  // height: fit-content;
  height: calc(100% - 140px - 1em);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > a {
    color: #d0d0d0;
    width: fit-content;
    font-size: calc(0.5em + 0.5vmin);
  }
  > ${Title} {
    @media (min-width: 768px) {
      font-size: calc(0.7em + 0.5vmin);
    }
  }
  @media (min-width: 500px) {
    height: calc(100% - 150px - 1em);
  }
  @media (min-width: 768px) {
    height: calc(100% - 120px - 1em);
  }
`

const Image = styled.div`
  height: 140px;
  width: 100%;
  background: url(${props => props.background});
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #f1f1f6;
  @media (min-width: 500px) {
    height: 150px;
  }
  @media (min-width: 768px) {
    height: 120px;
  }
`

const Container = styled(StackedDiv)`

`

const Article = ({variants, controls, title, logo, snippet, link, source}) => {
  return (
    <Container
      role="listitem"
      variants={variants}
      initial="hidden"
      animate={controls}
      >
      <Image background={logo} alt={`${source} Logo`}/>
      <Description>
        <Title>{title}</Title>
        <Link
          type='anchor'
          link={link}
          aria-label={`Link to read more on ${source}`}>
          read more from {source}
        </Link>
      </Description>
    </Container>
  )
}

export default Article
