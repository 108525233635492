import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

import { FEATURES } from "../../constants";
import { container, flexItem } from "../../constants/variants";
import { H2Styled as Header, Highlight } from "../../shared/Headers";
import { SectionDefault as Section } from "../../shared/Refactor/Sections";
import SectionItem from "./SectionItem";

const Features = () => {
  const [scrollPosition, setScrollPosition] = useState({});

  const handleScroll = (e) => {
    setScrollPosition({
      width: window.innerWidth,
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <Section hash="features" size="sm">
        <Header color="#f1f1f6">
          How <Highlight>APY.Finance</Highlight> <br /> Works
        </Header>
        {FEATURES.map((feature, idx) => (
          <SectionItem
            key={`Feature no. ${idx}`}
            isLast={idx === FEATURES.length - 1}
            src={feature.img}
            title={feature.title}
            header={feature.header}
            subText={feature.subText}
            imgSize={
              scrollPosition?.width > 768 ? feature.imgSize : feature.imgSizeMB
            }
            isReverse={idx % 2 !== 0}
            scrollPosition={scrollPosition}
          />
        ))}
      </Section>
    </div>
  );
};

export default Features;
