import React, { useEffect } from 'react'
import styled from 'styled-components'

import { SectionDefault } from '../shared/Refactor/Sections'
import { H2Heading, H3Title } from '../shared/TextContent'
import { GApageView } from '../utils/Analytics'
import { TERMS } from '../constants/terms'

const Section = styled(SectionDefault)`
  height: fit-content;
  padding: 3em 5em;
  color: #d0d0d0;
  font-family: archiaregular;
  p > span {
    font-family: archiabold;
  }
  p > a,
  & ol > li > p > a {
    text-decoration: none;
    pointer: cursor;
    border-bottom: 1px solid #d0d0d0;
    &:visited {
      color: #d0d0d0;
    }
  }
  p > ul {
    margin: .7rem auto;
    list-style: circle !important;
    list-style-type: circle;
    list-style-position: inside !important;
  }
  ol {
    margin: 0;
    padding: 0;
    & > li > h3 {
      display: inline-block;
    }
  }
`

const Title = styled(H3Title)`
  margin-left: 0;
  padding-left: 0;
  text-transform: none;
`

const Header = styled(H2Heading)`
  padding: 0;
  margin-left: 0;
  width: fit-content;
  margin-bottom: 0;
`

const Terms = () => {
  useEffect(() => { GApageView("terms of service"); }, []);
  return (
    <Section>
      <Header>{TERMS.title}</Header>
      <p>{TERMS.content}</p>
      <ol>
        {TERMS.list.map((item, idx) =>
          <li>
            <Title>{item.title}</Title>
            {item.content}
          </li>
        )}
      </ol>
    </Section>
  )
}

export default Terms
