import styled from "styled-components";
import { motion } from "framer-motion";

export const Grid = styled(motion.ul)`
  height: fit-content;
  width: 100%;
  padding: 0;
  grid-template-columns: auto;
  justify-content: space-between;
  display: grid;
`;

export const GridThree = styled(motion.ul)`
  height: fit-content;
  width: 100%;
  padding: 0;
  grid-template-columns: auto;
  justify-content: space-between;
  display: grid;
  grid-column-gap: calc(33.33% / 4.5);
  grid-template-columns: 1fr ;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Flex = styled(motion.ul)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0;
  width: fit-content;
  padding-right: 16px;
  max-width: 2400px;
  margin: 0;
  @media (min-width: 768px) {
    flex-direction: row;
    width: fit-content;
    padding-right: 16px;
  }
`;
