import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useMediaLayout } from 'use-media';
import ReactGA from 'react-ga';

import ScrollToTop from './utils/ScrollToTop';
import Video from './components/Hero/Video';
import Home from './components/Home';
import Footer from './components/Footer';
import Header from './components/Header';
import Menu from './components/Menu';
import CookiesConsent from './components/CookiesConsent';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import { cookieStorage } from './utils/Analytics';
import './App.css';
import Team from './components/Team/index';
import Press from './components/Press/index';
import Newsletter from './components/Newsletter';
import Governance from './components/Governance/Index';
import CortexModal from './components/Modals/CortexModal';

const Wrap = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
`;

const App = () => {
  const gaName = cookieStorage.getItem('_ga') && cookieStorage.getItem('_gid');
  const isWide = useMediaLayout({ minWidth: 768 });
  const [consent, setConsent] = useState(false);
  const [seen, setSeen] = useState(gaName ? true : false);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    consent && ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: true });
  }, [consent]);
  return (
    <Router>
      <Wrap>
        <CortexModal />
        {/* {isWide && <Video />} */}
        <AnimatePresence>
          {expanded && !isWide && (
            <Menu onClick={() => setExpanded(!expanded)} expanded={expanded} />
          )}
        </AnimatePresence>
        <Header expanded={expanded} onClick={() => setExpanded(!expanded)} />
        {!seen && <CookiesConsent setSeen={setSeen} setConsent={setConsent} />}
        <Switch>
          <ScrollToTop>
            <Route exact path="/" component={Home} />
            <Route path="/privacy-policy" component={Privacy} />
            <Route path="/terms-of-service" component={Terms} />
            <Route path="/investors" component={Team} />
            <Route path="/press" component={Press} />
            <Route path="/governance" component={Governance} />
            <Redirect to="/" />
          </ScrollToTop>
        </Switch>
        <Newsletter />
        <Footer />
      </Wrap>
    </Router>
  );
};

export default App;
