import React from 'react'
import styled, { css } from 'styled-components'

const Button = styled.button`
  margin: 0 10px;
  outline: none;
  border: none;
  background: transparent;
  color: #d0d0d0;
  font-family: archiamedium;
  font-size: 1.1em;
  cursor: pointer;
  ${props => props.action === 'cancel' && css`
    text-decoration: underline;
    padding: .3em;
  `}
  ${props => props.action === 'accept' && css`
    color: #202020;
    padding: .5em;
    background: #d0d0d0;
    border-radius: 5px;
  `}
`

export const NotificationButton = ({children, fn, action}) => {
  return (
    <Button onClick={fn} action={action}>{children}</Button>
  )
}
