import React, { useState } from "react";
import styled from "styled-components";
import { AnimateSharedLayout } from "framer-motion";

import { LinkTypes as Link } from "../../shared/Links";
import { Logo, Wordmark } from "../Logo";
import MobileNav from "./MobileNav";
import { StackedButton } from "../Buttons/StackedButton";
import AnimatedLink from "./AnimatedLink";
import { LINKS } from "../../constants/links";
import { useMedia } from "use-media";
import { useParams } from "react-router-dom";

const Wrap = styled.header`
  display: flex;
  height: 7vh;
  flex-direction: row;
  z-index: 200;
  padding: 2em 11em 0;
  justify-content: center;
  align-items: center;

  background-position: center;
  position: relative;
  @media (min-width: 768px) {
    padding: 2em 3em 0;
  }
  @media (min-width: 1024px) {
    padding: 2em 4em 0;
  }
`;

const Nav = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled(StackedButton)`
  margin: 0 5px 0 15px;
  position: absolute;
  right: 0;
  > div {
    height: 35px;
  }
  > button {
    height: 35px;
    padding: 0 5px;
    > p {
      padding: 0 7px;
      margin: 0;
    }
  }
  @media (min-width: 768px) {
    right: 3em;
  }
  @media (min-width: 1024px) {
    right: 4em;
  }
`;

const LogoLink = styled(Link)`
  position: absolute;
  left: 0;
  @media (min-width: 768px) {
    left: 3em;
  }
  @media (min-width: 1024px) {
    left: 4em;
  }
`;

const NavBar = (props) => {
  const hamburglar = useMedia({ maxWidth: "992px" });
  const laptopBreak = useMedia({ minWidth: "1025px" });
  const [selected, setSelected] = useState("");
  const [selectedActive, setActiveSelected] = useState("");
  const laptopLinks = [...LINKS.navBar];
  return hamburglar ? (
    <>
      <MobileNav {...props} />
    </>
  ) : (
    <Wrap>
      <Nav>
        <LogoLink key={"APY Logo"} type={"route"} link={"/"}>
          <Logo />
        </LogoLink>
        <AnimateSharedLayout>
          {laptopLinks.map((link, idx) => {
            return (
              <Link key={link.body} type={link.type} link={link.path}>
                <AnimatedLink
                  selected={selected}
                  onClick={() => {
                    if(link.body === "strategies"){
                      setActiveSelected(""); 
                      setSelected("")
                    } else{
                      setActiveSelected(link.path); 
                      setSelected(link.path)
                    }
                  }}
                  path={link.path}
                  key={link.body}
                  hover={() => setSelected(link.path)}
                  hoverEnd={() => setSelected(selectedActive)}
                  body={link.body}
                />
              </Link>
            );
          })}
        </AnimateSharedLayout>
        <Button link="https://dashboard.apy.finance/">
          <p>launch</p>
        </Button>
      </Nav>
    </Wrap>
  );
};

export default NavBar;
