import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export function Modal({ isOpen, close, children }) {
  return ReactDOM.createPortal(
    isOpen && (
      <Overlay onClick={close}>
        <div onClick={(evt) => evt.stopPropagation()}>{children}</div>
      </Overlay>
    ),
    document.querySelector('#modal-portal')
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(7px);
  z-index: 1000;
`;
