import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import Hamburglar from "./Hamburglar";
import { Wordmark } from "../Logo";
import { LinkTypes as Link } from "../../shared/Links";

const Wrap = styled(motion.header)`
  padding: 1em 2em;
  height: 5vh;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: hsl(0, 0%, 10%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${
    "" /* @media (min-width: 320px) {
    padding: 1em 3.25em;
  }
  @media (min-width: 360px) {
    padding: 1em 4em;
  }
  @media (min-width: 375px) {
    padding: 1em 4.5em;
  } */
  }
  ${
    "" /* @media (min-width: 425px) {
    padding: 1em 5em;
  } */
  }
  @media (min-width: 500px) {
    padding: 1em 3em;
  }
`;

const MobileNav = (props) => {
  const variants = {
    open: {
      backgroundColor: "hsla(0, 0%, 10%, 0)",
      transition: {
        delay: 0.1,
        duration: 0.1,
      },
    },
    closed: {
      backgroundColor: "hsla(0, 0%, 10%, 1)",
    },
  };

  return (
    <Wrap
      variants={variants}
      initial={{ backgroundColor: "hsla(0, 0%, 9%, 1)" }}
      animate={props.expanded ? "open" : "closed"}
    >
      <Link key={"APY Logo"} type={"route"} link={"/"}>
        <Wordmark />
      </Link>
      <Hamburglar {...props} />
    </Wrap>
  );
};

export default MobileNav;
