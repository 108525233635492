// Protocols
import aave from '../assets/defi-protocols/resized/aave.svg'
import curve from '../assets/defi-protocols/curve.svg'
import compound from '../assets/defi-protocols/resized/compound.svg'
import mstable from '../assets/defi-protocols/mstable.svg'
import uniswap from '../assets/defi-protocols/uniswap.svg'
import synthetix from '../assets/defi-protocols/Synthetix_icon.png'
import frax from '../assets/defi-protocols/frax.png'
import liquity from '../assets/defi-protocols/liquity.png';
import balancer from '../assets/defi-protocols/balancer.svg';
import convex from '../assets/defi-protocols/convex.svg';
import alchemix from '../assets/defi-protocols/alchemix.png';
import originProtocol from '../assets/defi-protocols/origin-protocol.svg';
import mim from '../assets/defi-protocols/mim.png'

export const PROTOCOLS = [

  {
    name: 'Curve',
    logo: curve,
    link: 'https://curve.fi/'
  },
  {
    name: 'Convex',
    logo: convex,
    link: 'https://www.convexfinance.com/'
  },
  {
    name: 'Aave',
    logo: aave,
    link: 'https://aave.com/'
  },
  {
    name: 'Synthetix',
    logo: synthetix,
    link: 'https://synthetix.io/'
  },
  {
    name: 'Frax',
    logo: frax,
    link: 'https://frax.finance/'
  },
  {
    name: 'Alchemix',
    logo: alchemix,
    link: 'https://alchemix.fi/'
  },
  {
    name: 'Origin',
    logo: originProtocol,
    link: 'https://www.originprotocol.com/en/'
  },
  {
    name: 'MIM',
    logo: mim,
    link: 'https://abracadabra.money/'
  },
]
