import React from "react";
import styled from "styled-components";

import { wordmark, logo } from "../constants/images";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  z-index: 100;
`;

const WMStyled = styled.img`
  height: 30px;
  width: auto;
  @media (min-width: 1024px) {
    height: 45px;
    width: calc(45px * 5);
  }
`;

const LogoStyled = styled.img`
  height: 50px;
  width: auto;
`;

export const Wordmark = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <WMStyled src={wordmark} alt="Logo for APY.finance" />
    </Wrapper>
  );
};

export const Logo = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <LogoStyled src={logo} alt="Logo for APY.finance" />
    </Wrapper>
  );
};
