import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

import { PRESS } from "../../constants";
import { flex, flexItem } from "../../constants/variants";
import {
  BodyText,
  H2Styled as Header,
  H3Title,
  Highlight,
} from "../../shared/Headers";
import { SectionDefault as Section } from "../../shared/Refactor/Sections";
import { GridThree as Container } from "../../shared/Refactor/Lists";
import Article from "./Article";
import { PageContainer } from "../../shared/Refactor/Containers";
import { ReactComponent as Email } from "../../assets/graphics/email.svg";
import { ReactComponent as LinkToBrand } from "../../assets/graphics/linkToBrand.svg";
import styled from "styled-components";
import { LinkTypes as Link } from "../../shared/Links";

const IconCircle = styled.div`
  padding: 10px;
  border-radius: 100%;
  background-color: #b454f1;
  margin-right: 1em;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconsContainer = styled.div`
  width: 100%;
  margin: 4em auto 0  ;
  justify-content: center;
  display: block;
  @media (min-width: 768px) {
    padding: 7em 0 4em;
    margin-bottom: 0;
    display: flex;
    width: 65%;
  }
  @media (min-width: 1200px) {
    width: 50%;

  }
`;
const Press = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <PageContainer>
      <Section hash="press" size="sm">
        <Header>
          Media <br /> <Highlight> Coverage</Highlight>
        </Header>
        <Container
          ref={ref}
          variants={flex}
          initial="hidden"
          animate={controls}
        >
          {PRESS.map((article, idx) => (
            <Article
              variants={flexItem}
              animate={controls}
              key={`article no. ${idx}`}
              link={article.link}
              logo={article.logo}
              snippet={article.snippet}
              source={article.source}
              title={article.title}
            />
          ))}
        </Container>
        <IconsContainer>
          {/*  */}
          <Link
            link={"mailto:support@apy.finance"}
            type={"anchor"}
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              marginRight: 20,
              marginBottom: "2em"
            }}
          >
            <div>
              <IconCircle>
                <Email style={{ width: "100%", height: "auto" }} />
              </IconCircle>
            </div>

            <BodyText style={{ margin: 0 }}>Contact Email </BodyText>
          </Link>
          <Link
            link={
              "https://drive.google.com/drive/folders/1AAnlELjrp-Gor1wlDTnIhcL2ZGLSJ0cT?usp=sharing"
            }
            type={"anchor"}
            style={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              marginBottom: "2em"
            }}
          >
            <div>
              <IconCircle>
                <LinkToBrand style={{ width: "100%", height: "auto" }} />
              </IconCircle>
            </div>
            <BodyText style={{ margin: 0 }}>Brand kit </BodyText>
          </Link>
        </IconsContainer>
      </Section>
    </PageContainer>
  );
};

export default Press;
